import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    margin-bottom: 20px;
    background-color: #04417777;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const Testimonial = ({ children }) => (
    <Wrapper>
        {children}
    </Wrapper>
);

export default Testimonial;